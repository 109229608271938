import { APP_TYPE } from 'config/common/devices';

export enum ERROR_TYPE {
  Range = 'RangeError',
  Reference = 'ReferenceError',
  Type = 'TypeError',

  // Custom Error
  Ad = 'AdError',
  Request = 'RequestError',
  Unknown = 'UnknownError',
}

/** Названия счетчиков прометея */
export enum COUNTERS_NAMES {
  Ad = 'ad_counter',
  ComponentError = 'component_error_counter',
  RepeatRequest = 'repeat_request_counter',
  RequestError = 'request_error_counter',
}

type CountAdPropsType = {
  // Тип устройства
  deviceType: APP_TYPE;
  // Имя баннера
  adName: string;
  // Флаг состояния загрузки рекламы
  successful: string;
  // Причина, по которой упала реклама
  reason: string | undefined;
};

type CountComponentErrorPropsType = {
  // Тип устройства
  deviceType: APP_TYPE;
  // Тип ошибки
  errorType: ERROR_TYPE;
  // Название компонента
  componentName: string;
};

type CountRepeatRequestPropsType = {
  // Ручка, которая запрашивается повторно
  entrypoint: string;
  // Счетчик повторных запросов
  counter: string;
};

type CountRequestErrorPropsType = {
  // Ручка, которая упала с ошибкой
  entrypoint: string;
  // Статус ошибки
  status: string;
};

type COUNTERS_PARAMS_TYPES = {
  [COUNTERS_NAMES.Ad]: CountAdPropsType;
  [COUNTERS_NAMES.ComponentError]: CountComponentErrorPropsType;
  [COUNTERS_NAMES.RepeatRequest]: CountRepeatRequestPropsType;
  [COUNTERS_NAMES.RequestError]: CountRequestErrorPropsType;
};

export type MetricsParamsType<T extends COUNTERS_NAMES> =
  COUNTERS_PARAMS_TYPES[T];

export type MetricsBatchType<T extends COUNTERS_NAMES = COUNTERS_NAMES> = {
  counterName: T;
  params: MetricsParamsType<T>;
};

export type ProcessedMetricsBatchType<
  T extends COUNTERS_NAMES = COUNTERS_NAMES,
> = MetricsBatchType<T>;

export enum SWITCHER_VALUE {
  on = '1',
  off = '0',
}
