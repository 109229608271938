import { getDataFromMemoryCache } from 'api/requestHandler/memoryCache/getCache';

import { TOPICS_KEY_NAME, TOPICS_ENTRYPOINT_NAME } from './constants';
import { TopicsFromCacheType } from './typings';

/**
 * Функция, которая возвращает данные из кэша
 */
export const getTopicsFromMemoryCache = () =>
  getDataFromMemoryCache<TopicsFromCacheType>({
    key: TOPICS_KEY_NAME,
    entrypoint: TOPICS_ENTRYPOINT_NAME,
  });
