import {
  PEROXIDE_APIS,
  URANYL_APIS,
  COMMENTS_APIS,
  RECOMMEND_APIS,
  RECOMMEND_PLUS_APIS,
  WINLINE_APIS,
  RAMBLER_APIS,
  ID_APIS,
  COOLSTREAM_APIS,
  HOROSCOPES_APIS,
  TRAVEL_APIS,
} from 'config/common/api/typings';
import { COMMON_REDIS_RETRY_INTERVAL_IN_MS } from 'config/common/profiles/constants';
import {
  REC_MOB_CLUSTER_FEED_BLOCK_ID,
  REC_DESK_TOP_NOW_BLOCK_ID,
  REC_DESK_CLUSTER_FEED_BLOCK_ID,
  REC_DESK_HOME_BLOCK_ID,
  REC_MOB_HOME_BLOCK_ID,
  REC_DESK_TOPIC_BLOCK_ID,
  REC_MOB_TOPIC_BLOCK_ID,
  RCM_ID,
  REC_MOB_WIDGET_BLOCK_ID,
  REC_DESK_WIDGET_BLOCK_ID,
  REC_DESK_TOP_NEWS_WIDGET_BLOCK_ID,
  REC_DESK_REGION_BLOCK_ID,
  REC_MOB_REGION_BLOCK_ID,
  REC_DESK_HORIZONTAL_BLOCK_ID,
  REC_MOB_HORIZONTAL_BLOCK_ID,
  REC_DESK_VERTICAL_5_BLOCK_ID,
  REC_DESK_VERTICAL_1_BLOCK_ID,
  REC_DESK_HORIZONTAL_BLOCK_ID_REDESIGN,
  REC_MOBILE_VERTICAL_BLOCK_ID,
  REC_DESK_GREEN_TOPIC_BLOCK_ID,
  REC_MOB_GREEN_TOPIC_BLOCK_ID,
  REC_DESK_ARTICLE_FEED_BLOCK_ID,
  REC_MOB_ARTICLE_FEED_BLOCK_ID,
  REC_DESK_GREEN_TOP_NEWS_WIDGET_BLOCK_ID,
  REC_DESK_GREEN_TOP_NOW_BLOCK_ID,
} from 'config/common/recommend';

import { ConfigType } from '../typings';

export { PUSHWOOSH } from './commonSettings';

export const PORT = '';

export const PROJECTS_DOMAINS = {
  news: 'news.stage3.aggregator.rambler.ru',
  auto: 'auto.stage3.aggregator.rambler.ru',
  woman: 'woman.stage3.aggregator.rambler.ru',
  sport: 'sport.stage3.aggregator.rambler.ru',
  weekend: 'weekend.stage3.aggregator.rambler.ru',
  finance: 'finance.stage3.aggregator.rambler.ru',
  doctor: 'doctor.stage3.aggregator.rambler.ru',
  games: 'games.stage3.aggregator.rambler.ru',
  kino: 'kino.stage3.aggregator.rambler.ru',
  travel: 'travel.stage3.aggregator.rambler.ru',
  horoscopes: 'horoscopes.stage3.aggregator.rambler.ru',
};

export const API_URL = PEROXIDE_APIS.stage3;
export const URANYL_URL = URANYL_APIS.stage1;
export const COMMENTS_URL = COMMENTS_APIS.prod;
export const RECOMMEND_URL = RECOMMEND_APIS.prod;
export const RECOMMEND_PLUS_URL = RECOMMEND_PLUS_APIS.prod;
export const WINLINE_URL = WINLINE_APIS.prod;
export const RAMBLER_URL = RAMBLER_APIS.stage1;
export const ID_URL = ID_APIS.prod;
export const COOLSTREAM_URL = COOLSTREAM_APIS.stage1;
export const HOROSCOPES_URL = HOROSCOPES_APIS.prod;
export const TRAVEL_URL = TRAVEL_APIS.stage3;

export const API_TIMEOUT = 2000;
export const API_TIMEOUT_CLIENT = 10000;

export const API_KEEP_ALIVE_TIMEOUT = 5;
export const API_KEEP_ALIVE_MAX = 1000;

export const METRICS = true;
export const ENABLE_CLIENT_METRICS = true;

export const LOGLEVEL = 'ERROR';
export const LOGGING = true;

export const SENTRY_DSN =
  'https://703b8725b87c6d034967c971fb6618e9@sentry-saas.rambler-co.ru/10';
export const SENTRY_ENABLED_SERVER = true;
// Частота сбора ошибок
export const SENTRY_SAMPLE_RATE_SERVER = 1;
// Частота сбора событий ДО ошибки
export const SENTRY_TRACES_SAMPLE_RATE_SERVER = 0.5;

export const SENTRY_ENABLED_BROWSER = true;
// Частота сбора ошибок
export const SENTRY_SAMPLE_RATE_BROWSER = 1;
// Частота сбора событий ДО ошибки
export const SENTRY_TRACES_SAMPLE_RATE_BROWSER = 0.5;

export const REDIS = {
  CLUSTER: [
    '0vm0009.dev.redis.rambler.tech',
    '0vm0019.dev.redis.rambler.tech',
    '1vm0009.dev.redis.rambler.tech',
    '1vm0012.dev.redis.rambler.tech',
    '2vm0009.dev.redis.rambler.tech',
    '2vm0011.dev.redis.rambler.tech',
  ],
  OPTIONS: {
    scaleReads: 'slave',
    redisOptions: {
      port: 6379,
      password: process.env.REDIS_PASSWORD,
      enableOfflineQueue: false,
      dropBufferSupport: true,
      enableReadyCheck: true,
      maxRedirections: 2,
      retryDelayOnFailover: COMMON_REDIS_RETRY_INTERVAL_IN_MS,
      retryDelayOnClusterDown: COMMON_REDIS_RETRY_INTERVAL_IN_MS,
      // Небольшая задержка перед возможными передвижениями кластеров
      retryDelayOnMoved: 100,
    },
  },
};

export const CACHE: ConfigType['CACHE'] = {
  ALL: true,
  // Отключен за ненадобностью (есть MEMORY_CACHE)
  REDIS_CACHE: false,
};

export const RECOMMEND_BLOCK_ID: ConfigType['RECOMMEND_BLOCK_ID'] = {
  clusterFeedMobile: REC_MOB_CLUSTER_FEED_BLOCK_ID.test,
  clusterFeedDesktop: REC_DESK_CLUSTER_FEED_BLOCK_ID.test,
  desktopWidget: REC_DESK_WIDGET_BLOCK_ID.test,
  homeDesktop: REC_DESK_HOME_BLOCK_ID.test,
  homeMobile: REC_MOB_HOME_BLOCK_ID.test,
  mobileWidget: REC_MOB_WIDGET_BLOCK_ID.test,
  regionDesktop: REC_DESK_REGION_BLOCK_ID.test,
  regionMobile: REC_MOB_REGION_BLOCK_ID.test,
  horizontalDesktop: REC_DESK_HORIZONTAL_BLOCK_ID.test,
  horizontalDesktopRedesign: REC_DESK_HORIZONTAL_BLOCK_ID_REDESIGN.test,
  horizontalMobile: REC_MOB_HORIZONTAL_BLOCK_ID.test,
  vertical5Desktop: REC_DESK_VERTICAL_5_BLOCK_ID.test,
  vertical1Desktop: REC_DESK_VERTICAL_1_BLOCK_ID.test,
  verticalMobile: REC_MOBILE_VERTICAL_BLOCK_ID.test,
  topicDesktop: REC_DESK_TOPIC_BLOCK_ID.test,
  topicMobile: REC_MOB_TOPIC_BLOCK_ID.test,
  topNewsWidgetDesktop: REC_DESK_TOP_NEWS_WIDGET_BLOCK_ID.test,
  topNowDesktop: REC_DESK_TOP_NOW_BLOCK_ID.test,
  greenTopicDesktop: REC_DESK_GREEN_TOPIC_BLOCK_ID.test,
  greenTopicMobile: REC_MOB_GREEN_TOPIC_BLOCK_ID.test,
  articleFeedDesktop: REC_DESK_ARTICLE_FEED_BLOCK_ID.test,
  articleFeedMobile: REC_MOB_ARTICLE_FEED_BLOCK_ID.test,
  greenTopNewsWidgetDesktop: REC_DESK_GREEN_TOP_NEWS_WIDGET_BLOCK_ID.test,
  greenTopNowDesktop: REC_DESK_GREEN_TOP_NOW_BLOCK_ID.test,
};

export const RECOMMEND_RCM_ID: ConfigType['RECOMMEND_RCM_ID'] = RCM_ID.test;
