import {
  selectVariablesBatchInterval,
  selectVariablesBatchSampleClientLeavePercent,
  selectVariablesBatchSamplePercent,
} from 'common/redux/runtime/selectors';
import {
  MetricsBatchType,
  COUNTERS_NAMES,
  ProcessedMetricsBatchType,
} from 'server/typings';
import { getRandomRange } from 'utils/getRandomRange';

import { CLIENT_METRICS_PATHS } from '../../constants';
import { hitServerPushFailCount } from '../../index';

import { LOCAL_COUNTERS } from './constants';

/** Пакет метрик будет отправляться каждые 10 секунд */
const BATCH_INTERVAL = 10;

/**
 * Процент сэмплирования.
 * 100 означает, что будет отправляться 100% пакетов
 */
const BATCH_SAMPLE_PERCENT = 10;

/**
 * Функция-cоздатель batch для отправки запросов пакетом
 */
const createMetricsBatch = () => ({
  metrics: [] as ProcessedMetricsBatchType[],
  intervalId: null as NodeJS.Timeout | null,
  interval: BATCH_INTERVAL,
  samplePercent: BATCH_SAMPLE_PERCENT,
  sampleClientLeavePercent: BATCH_SAMPLE_PERCENT,

  /**
   * Метод инициализации функции-создателя пакетов.
   * @param props - пропсы
   * @param props.newInterval - новый интервал того, с какой частотой будут делаться запросы. Измеряется в секундах;
   * @param props.newSamplePercent - процент запросов с метриками, которые будут слаться на клиент.
   * @param props.newSampleClientLeavePercent - процент юзеров для которых будут отправлены метрики при выходе со страницы
   */
  initMetricsBatch({
    newInterval = BATCH_INTERVAL,
    newSamplePercent = BATCH_SAMPLE_PERCENT,
    newSampleClientLeavePercent = BATCH_SAMPLE_PERCENT,
  }: {
    newInterval?: number;
    newSamplePercent?: number;
    newSampleClientLeavePercent?: number;
  }) {
    this.interval = newInterval * 1000;
    this.samplePercent = newSamplePercent;
    this.sampleClientLeavePercent = newSampleClientLeavePercent;
    this.startTimeout();
  },

  /**
   * Сохраняет счечик в массиве для дальнейшей отправки
   * @param counter - данные для счетчика
   * @param counter.counterName – название счетчика
   * @param counter.params – данные для отправки в счетчик
   */
  pushToCounters<T extends COUNTERS_NAMES>(counter: MetricsBatchType<T>) {
    if (__SERVER__) {
      this.processServerCounters(counter);

      return;
    }

    this.metrics.push({
      counterName: counter.counterName,
      params: counter.params,
    });
  },

  /**
   * Обработчик серверных счетчиков.
   * Если счетчик на сервере есть - записывает его.
   * @param counter - данные о счетчике и тех данных, что надо сохранить.
   */
  processServerCounters<T extends COUNTERS_NAMES>(
    counter: MetricsBatchType<T>,
  ) {
    if (__BROWSER__) return;

    if (!LOCAL_COUNTERS[counter.counterName] || !counter.params) return;

    LOCAL_COUNTERS[counter.counterName]?.(counter.params);

    if (!LOCAL_COUNTERS[counter.counterName]) {
      hitServerPushFailCount(counter.counterName);
    }
  },

  /**
   * Отправка массива метрик
   */
  async sendRequests() {
    if (!this.metrics.length) return;

    try {
      await fetch(CLIENT_METRICS_PATHS.Batch, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          metrics: this.metrics,
        }),
      });

      this.metrics = [];
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Обработчик события beforeunload
   * Подчищает сайд эфекты и отправляет массив метрик перед сменой урла или закрытием вкладки
   */
  beforeUnload() {
    /**
     * Ограничиваем, запрещая интервалу делать запросы
     *  если юзеру не выпало число, ниже указанного процента.
     * С этим параметром надо быть аккуратнее, ибо, если
     *  юзер уйдет со страницы, то все данные будут потеряны.
     */
    const random = getRandomRange(0, 100);

    if (random > this.sampleClientLeavePercent) return;

    this.sendRequests();

    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }

    window.removeEventListener('beforeunload', this.sendRequests);
  },

  /**
   * Навешивание событий при которых отправляем запросы
   */
  startTimeout() {
    if (__SERVER__) return this;

    this.intervalId = setInterval(() => {
      /**
       * Ограничиваем, запрещая интервалу делать запросы
       *  если юзеру не выпало число, ниже указанного процента.
       */
      const random = getRandomRange(0, 100);
      if (random > this.samplePercent) return;

      this.sendRequests();
    }, this.interval);

    window.addEventListener('beforeunload', this.beforeUnload.bind(this));

    return this;
  },
});

const metricsBatch = createMetricsBatch();

const initClientMetrics = (state: AppState) => {
  metricsBatch.initMetricsBatch({
    newInterval: selectVariablesBatchInterval(state),
    newSamplePercent: selectVariablesBatchSamplePercent(state),
    newSampleClientLeavePercent:
      selectVariablesBatchSampleClientLeavePercent(state),
  });
};

export { metricsBatch, initClientMetrics };
