import { PUIDS } from 'config/common/puids/typings';

import { BANNERS, BannersDataType } from '../typings';

export const doctor: BannersDataType = {
  desktop: {
    BEGUN_PAD_ID: 455225850,
    banners: {
      [BANNERS.Topline]: {
        display: 'ssp',
        begun: 542326617,
      },
      [BANNERS.Billboard]: {
        display: 'ssp',
        begun: 455226249,
      },
      [BANNERS.BillboardCommercial1]: {
        display: 'sspScroll',
        begun: 579291216,
      },
      [BANNERS.BillboardCommercial2]: {
        display: 'sspScroll',
        begun: 579291218,
      },
      [BANNERS.BillboardCommercial3]: {
        display: 'sspScroll',
        begun: 579291220,
      },
      [BANNERS.Parallax]: {
        display: 'sspScroll',
        begun: 455226267,
      },
      [BANNERS['240x400']]: {
        display: 'ssp',
        begun: 455226245,
      },
      [BANNERS['240x400_2']]: {
        display: 'sspScroll',
        begun: 455226247,
      },
      [BANNERS.Context]: {
        // native 1
        display: 'ssp',
        data: {
          [PUIDS.puid61]: '510',
        },
        begun: 455226257,
      },
      [BANNERS.Native]: {
        // native 2
        display: 'sspScroll',
        data: {
          [PUIDS.puid61]: '512',
        },
        begun: 455226259,
      },
      [BANNERS.Superfooter]: {
        display: 'sspScroll',
        begun: 455226243,
      },
      [BANNERS['100x70']]: {
        display: 'sspScroll',
        begun: 455226251,
      },
      [BANNERS.Inread]: {
        display: 'ssp',
        begun: 455226255,
      },
      [BANNERS.NativePartner]: {
        display: 'sspScroll',
        begun: 543496355,
        dir1: {
          [PUIDS.puid61]: '849',
        },
      },
      [BANNERS.Ingallery]: {
        display: 'reloadssp',
        begun: 558708605,
      },
      [BANNERS.PromoWidget]: {
        data: {
          puid6: 'DOCTORRAMBLERRU_MAIN',
          puid18: 'DOCTORRAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579498258,
      },
      [BANNERS.ShowUp]: {
        display: 'ssp',
        begun: 579503450,
      },
    },
  },
  mobile: {
    BEGUN_PAD_ID: 459111976,
    banners: {
      [BANNERS.TopBanner]: {
        begun: 459111986,
        p1: 'carht',
      },
      [BANNERS.Rich]: {
        begun: 459112386,
      },
      [BANNERS.Fullscreen]: {
        begun: 459112384,
      },
      [BANNERS.Listing1]: {
        begun: 459111988,
      },
      [BANNERS.Listing2]: {
        begun: 459111996,
      },
      [BANNERS.Listing3]: {
        begun: 459112006,
      },
      [BANNERS.Listing4]: {
        begun: 459112018,
      },
      [BANNERS.Content1]: {
        begun: 459112020,
        p1: 'carhu',
      },
      [BANNERS.Content2]: {
        begun: 459112160,
        p1: 'carhv',
      },
      [BANNERS.Content3]: {
        begun: 459112162,
        p1: 'carhw',
      },
      [BANNERS.Content4]: {
        begun: 459112218,
        p1: 'carhx',
      },
      [BANNERS.Spec]: {
        begun: 459113216,
      },
      [BANNERS.ContentSpec]: {
        begun: 459113982,
      },
      [BANNERS.Inpage]: {
        begun: 459112220,
      },
      [BANNERS.Footer]: {
        begun: 459112382,
        p1: 'carhy',
      },
      [BANNERS.PromoWidget]: {
        data: {
          puid6: 'DOCTORRAMBLERRU_MAIN',
          puid18: 'DOCTORRAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579498260,
      },
    },
  },
};
