import { PUIDS } from 'config/common/puids/typings';

import { BANNERS, BannersDataType } from '../typings';

export const travel: BannersDataType = {
  desktop: {
    BEGUN_PAD_ID: 432426576,
    banners: {
      [BANNERS.Topline]: {
        display: 'ssp',
        begun: 542317539,
      },
      [BANNERS.Billboard]: {
        display: 'ssp',
        begun: 432426596,
      },
      [BANNERS.Parallax]: {
        display: 'sspScroll',
        begun: 550851307,
      },
      [BANNERS['240x400']]: {
        display: 'ssp',
        begun: 432426594,
      },
      [BANNERS['240x400_2']]: {
        display: 'sspScroll',
        begun: 457617430,
      },
      [BANNERS.Context]: {
        // native 1
        display: 'ssp',
        data: {
          [PUIDS.puid61]: '511',
        },
        begun: 550303311,
      },
      [BANNERS.Native]: {
        // native 2
        display: 'sspScroll',
        data: {
          [PUIDS.puid61]: '513',
        },
        clusterFeedReadAlso: {
          [PUIDS.puid61]: '853',
        },
        begun: 550322711,
      },
      [BANNERS.Superfooter]: {
        display: 'sspScroll',
        begun: 432426586,
      },
      [BANNERS['100x70']]: {
        display: 'sspScroll',
        begun: 455685192,
      },
      [BANNERS.Inread]: {
        display: 'ssp',
        begun: 551399559,
      },
      [BANNERS.NativePartner]: {
        // Native3
        display: 'sspScroll',
        begun: 550855295,
        dir1: {
          [PUIDS.puid61]: '852',
        },
      },
      [BANNERS.Ingallery]: {
        display: 'reloadssp',
        begun: 550855363,
      },
      [BANNERS.PromoWidget]: {
        data: {
          puid6: 'TRAVELRAMBLERRU_MAIN',
          puid18: 'TRAVELRAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579498262,
      },
      [BANNERS.ShowUp]: {
        display: 'ssp',
        begun: 579503448,
      },
    },
  },
  mobile: {
    BEGUN_PAD_ID: 550850991,
    banners: {
      [BANNERS.TopBanner]: {
        begun: 551421999,
        p1: 'carht',
      },
      [BANNERS.Rich]: {
        begun: 551486961,
      },
      [BANNERS.Fullscreen]: {
        begun: 551424799,
      },
      [BANNERS.Listing1]: {
        begun: 551467953,
      },
      [BANNERS.Listing2]: {
        begun: 551468831,
      },
      [BANNERS.Listing3]: {
        begun: 551469223,
      },
      [BANNERS.Listing4]: {
        begun: 551471813,
      },
      [BANNERS.Content1]: {
        begun: 551472767,
        p1: 'carhu',
      },
      [BANNERS.Content2]: {
        begun: 551473449,
        p1: 'carhv',
      },
      [BANNERS.Content3]: {
        begun: 551475233,
        p1: 'carhw',
      },
      [BANNERS.Content4]: {
        begun: 551475993,
        p1: 'carhx',
      },
      [BANNERS.Spec]: {
        begun: 551425401,
      },
      [BANNERS.ContentSpec]: {
        begun: 551425407,
      },
      [BANNERS.Inpage]: {
        begun: 551637939,
      },
      [BANNERS.Footer]: {
        begun: 551482909,
        p1: 'carhy',
      },
      [BANNERS.PromoWidget]: {
        data: {
          puid6: 'TRAVELRAMBLERRU_MAIN',
          puid18: 'TRAVELRAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579498264,
      },
    },
  },
};
