export enum PUIDS {
  puid6 = 'puid6',
  puid15 = 'puid15',
  puid18 = 'puid18',
  puid31 = 'puid31',
  puid41 = 'puid41',
  puid48 = 'puid48',
  puid54 = 'puid54',
  puid59 = 'puid59',
  puid61 = 'puid61',
  puid62 = 'puid62',
}
