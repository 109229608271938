/**
 * Имена query параметров, которые могут иметь разнообразные значения
 */
export enum CUSTOM_QUERIES {
  forceClusterFeed = 'forceClusterFeed',
}

/**
 * Имена query параметров, которые имеют значения 0 или 1
 */
export enum ACCEPTED_SWITCHERS {
  forceAdFallback = 'forceAdFallback',
  forceBot = 'forceBot',
  forceRussia = 'forceRussia',
  // TODO(NEWS-0000): только для теста, после – удалить
  forceArticle = 'forceArticle',

  // Тумблеры с админки
  gigaEnabled = 'gigaEnabled',
  promoWidgetEnabled = 'promoWidgetEnabled',
  promoWidgetPlaceholderEnabled = 'promoWidgetPlaceholderEnabled',
  changeMeToUpdateTopics = 'changeMeToUpdateTopics',
}
