import { PUIDS } from 'config/common/puids/typings';

import { BANNERS, BannersDataType } from '../typings';

export const auto: BannersDataType = {
  desktop: {
    BEGUN_PAD_ID: 455549814,
    banners: {
      [BANNERS.Topline]: {
        display: 'ssp',
        begun: 542288985,
      },
      [BANNERS.Billboard]: {
        display: 'ssp',
        begun: 455549824,
      },
      [BANNERS.BillboardCommercial1]: {
        display: 'sspScroll',
        begun: 579291216,
      },
      [BANNERS.BillboardCommercial2]: {
        display: 'sspScroll',
        begun: 579291218,
      },
      [BANNERS.BillboardCommercial3]: {
        display: 'sspScroll',
        begun: 579291220,
      },
      [BANNERS.Parallax]: {
        display: 'sspScroll',
        begun: 455549850,
      },
      [BANNERS['240x400']]: {
        display: 'ssp',
        begun: 455549826,
      },
      [BANNERS['240x400_2']]: {
        display: 'sspScroll',
        begun: 455549834,
      },
      [BANNERS.Context]: {
        // native 1
        display: 'ssp',
        data: {
          [PUIDS.puid61]: '165',
        },
        begun: 455549842,
      },
      [BANNERS.Native]: {
        // native 2
        display: 'sspScroll',
        data: {
          [PUIDS.puid61]: '166',
        },
        begun: 455549844,
      },
      [BANNERS.Superfooter]: {
        display: 'sspScroll',
        begun: 455549874,
      },
      [BANNERS['100x70']]: {
        display: 'sspScroll',
        begun: 455549888,
      },
      [BANNERS.Inread]: {
        display: 'ssp',
        begun: 455549838,
      },
      [BANNERS.NativePartner]: {
        display: 'sspScroll',
        begun: 473298088,
        dir1: {
          [PUIDS.puid61]: '845',
        },
      },
      [BANNERS.Ingallery]: {
        display: 'reloadssp',
        begun: 530718609,
      },
      [BANNERS.PromoWidget]: {
        data: {
          puid6: 'AUTORAMBLERRU_MAIN',
          puid18: 'AUTORAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579498266,
      },
      [BANNERS.ShowUp]: {
        display: 'ssp',
        begun: 579503438,
      },
    },
  },
  mobile: {
    BEGUN_PAD_ID: 460649690,
    banners: {
      [BANNERS.TopBanner]: {
        begun: 460649728,
        p1: 'bzmem',
      },
      [BANNERS.Rich]: {
        begun: 460649788,
      },
      [BANNERS.Fullscreen]: {
        begun: 460649786,
      },
      [BANNERS.Listing1]: {
        begun: 460649732,
      },
      [BANNERS.Listing2]: {
        begun: 460649734,
      },
      [BANNERS.Listing3]: {
        begun: 460649736,
      },
      [BANNERS.Listing4]: {
        begun: 460649742,
      },
      [BANNERS.Content1]: {
        begun: 460649768,
        p1: 'bymhu',
      },
      [BANNERS.Content2]: {
        begun: 460649770,
        p1: 'bymhv',
      },
      [BANNERS.Content3]: {
        begun: 460649772,
        p1: 'bymhw',
      },
      [BANNERS.Content4]: {
        begun: 460649774,
        p1: 'bymhx',
      },
      [BANNERS.Spec]: {
        begun: 460649812,
      },
      [BANNERS.ContentSpec]: {
        begun: 460649816,
      },
      [BANNERS.Inpage]: {
        begun: 460649776,
      },
      [BANNERS.Footer]: {
        begun: 460649778,
        p1: 'bymhy',
      },
      [BANNERS.PromoWidget]: {
        data: {
          puid6: 'AUTORAMBLERRU_MAIN',
          puid18: 'AUTORAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579498270,
      },
    },
  },
};
