export enum RCM_BLOCK_TYPE {
  articleFeedDesktop = 'articleFeedDesktop',
  articleFeedMobile = 'articleFeedMobile',
  clusterFeedDesktop = 'clusterFeedDesktop',
  clusterFeedMobile = 'clusterFeedMobile',
  desktopWidget = 'desktopWidget',
  greenTopicDesktop = 'greenTopicDesktop',
  greenTopicMobile = 'greenTopicMobile',
  greenTopNewsWidgetDesktop = 'greenTopNewsWidgetDesktop',
  greenTopNowDesktop = 'greenTopNowDesktop',
  homeDesktop = 'homeDesktop',
  homeMobile = 'homeMobile',
  horizontalDesktop = 'horizontalDesktop',
  horizontalDesktopRedesign = 'horizontalDesktopRedesign',
  horizontalMobile = 'horizontalMobile',
  mobileWidget = 'mobileWidget',
  regionDesktop = 'regionDesktop',
  regionMobile = 'regionMobile',
  topicDesktop = 'topicDesktop',
  topicMobile = 'topicMobile',
  topNewsWidgetDesktop = 'topNewsWidgetDesktop',
  topNowDesktop = 'topNowDesktop',
  vertical1Desktop = 'vertical1Desktop',
  vertical5Desktop = 'vertical5Desktop',
  verticalMobile = 'verticalMobile',
}
