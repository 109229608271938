export enum AUTOTAG_TYPE {
  auto = 'auto',
  category = 'category',
  city = 'city',
  country = 'country',
  games = 'games',
  media = 'media',
  movie = 'movie',
  organization = 'organization',
  person = 'person',
  region = 'region',
}
