import { PUIDS } from 'config/common/puids/typings';

import { BANNERS, BannersDataType } from '../typings';

export const common: BannersDataType = {
  desktop: {
    BEGUN_PAD_ID: 579505410,
    banners: {
      [BANNERS.Topline]: {
        display: 'ssp',
        begun: 579506534,
      },
      [BANNERS.Billboard]: {
        display: 'ssp',
        begun: 579505414,
      },
      [BANNERS.BillboardCommercial1]: {
        display: 'sspScroll',
        begun: 579291216,
      },
      [BANNERS.BillboardCommercial2]: {
        display: 'sspScroll',
        begun: 579291218,
      },
      [BANNERS.BillboardCommercial3]: {
        display: 'sspScroll',
        begun: 579291220,
      },
      [BANNERS.Parallax]: {
        display: 'sspScroll',
        begun: 579505502,
      },
      [BANNERS['240x400']]: {
        display: 'reloadssp',
        begun: 579505416,
      },
      [BANNERS['240x400_2']]: {
        display: 'sspScroll',
        begun: 579505500,
      },
      [BANNERS.Context]: {
        // native 1
        display: 'ssp',
        data: {
          [PUIDS.puid61]: '193',
        },
        begun: 579505506,
      },
      [BANNERS.Native]: {
        // native 2
        display: 'sspScroll',
        data: {
          [PUIDS.puid61]: '194',
        },
        begun: 579505508,
      },
      [BANNERS.Superfooter]: {
        display: 'sspScroll',
        begun: 579505418,
      },
      [BANNERS['100x70']]: {
        display: 'sspScroll',
        begun: 579505498,
      },
      [BANNERS.Inread]: {
        display: 'ssp',
        begun: 579505512,
      },
      [BANNERS.NativePartner]: {
        // Native3
        display: 'sspScroll',
        begun: 579505510,
        dir1: {
          [PUIDS.puid61]: '830',
        },
      },
      [BANNERS.Ingallery]: {
        display: 'reloadssp',
        begun: 579505652,
      },
      [BANNERS.PromoWidget]: {
        data: {
          puid6: 'NEWSRAMBLERRU_MAIN',
          puid18: 'NEWSRAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579505486,
      },
      [BANNERS.ShowUp]: {
        display: 'ssp',
        begun: 579505488,
      },
      [BANNERS.Fullscreen]: {
        display: 'ssp',
        begun: 579505504,
      },
    },
  },
  mobile: {
    BEGUN_PAD_ID: 579505412,
    banners: {
      [BANNERS.TopBanner]: {
        begun: 579505420,
        p1: 'bzmeq',
      },
      [BANNERS.Rich]: {
        begun: 579505496,
      },
      [BANNERS.Fullscreen]: {
        begun: 579505538,
      },
      [BANNERS.Listing1]: {
        begun: 579505422,
      },
      [BANNERS.Listing2]: {
        begun: 579505424,
      },
      [BANNERS.Listing3]: {
        begun: 579505426,
      },
      [BANNERS.Listing4]: {
        begun: 579505430,
      },
      [BANNERS.Content1]: {
        begun: 579505432,
        p1: 'bwxfh',
      },
      [BANNERS.Content2]: {
        begun: 579505434,
        p1: 'bymxx',
      },
      [BANNERS.Content3]: {
        begun: 579505436,
        p1: 'bymxy',
      },
      [BANNERS.Content4]: {
        begun: 579505438,
        p1: 'bymxz',
      },
      [BANNERS.Spec]: {
        begun: 579505428,
      },
      [BANNERS.ContentSpec]: {
        begun: 579505544,
      },
      [BANNERS.Inpage]: {
        begun: 579505540,
      },
      [BANNERS.Footer]: {
        begun: 579505536,
        p1: 'bymya',
      },
      [BANNERS.PromoWidget]: {
        data: {
          puid6: 'NEWSRAMBLERRU_MAIN',
          puid18: 'NEWSRAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579505494,
      },
    },
  },
};
