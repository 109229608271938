import { PUIDS } from 'config/common/puids/typings';

import { BANNERS, BannersDataType } from '../typings';

export const kino: BannersDataType = {
  desktop: {
    BEGUN_PAD_ID: 507925505,
    banners: {
      [BANNERS.Topline]: {
        display: 'ssp',
        begun: 542288915,
      },
      [BANNERS.Billboard]: {
        display: 'ssp',
        begun: 507926021,
      },
      [BANNERS.BillboardCommercial1]: {
        display: 'sspScroll',
        begun: 579291216,
      },
      [BANNERS.BillboardCommercial2]: {
        display: 'sspScroll',
        begun: 579291218,
      },
      [BANNERS.BillboardCommercial3]: {
        display: 'sspScroll',
        begun: 579291220,
      },
      [BANNERS.Parallax]: {
        display: 'sspScroll',
        begun: 507962097,
      },
      [BANNERS['240x400']]: {
        display: 'ssp',
        begun: 507946699,
      },
      [BANNERS['240x400_2']]: {
        display: 'sspScroll',
        begun: 507947931,
      },
      [BANNERS.Context]: {
        // native 1
        display: 'ssp',
        data: {
          [PUIDS.puid61]: '326',
        },
        begun: 507957139,
      },
      [BANNERS.Native]: {
        // native 2
        display: 'sspScroll',
        data: {
          [PUIDS.puid61]: '327',
        },
        begun: 507957809,
      },
      [BANNERS.Superfooter]: {
        display: 'sspScroll',
        begun: 507966359,
      },
      [BANNERS['100x70']]: {
        display: 'sspScroll',
        begun: 508007707,
      },
      [BANNERS.Inread]: {
        display: 'ssp',
        begun: 507952069,
      },
      [BANNERS.NativePartner]: {
        // Native3
        display: 'sspScroll',
        begun: 507960299,
        dir1: {
          [PUIDS.puid61]: '839',
        },
      },
      [BANNERS.Ingallery]: {
        display: 'reloadssp',
        begun: 558707649,
      },
      [BANNERS.PromoWidget]: {
        data: {
          puid6: 'KINORAMBLERRU_MAIN',
          puid18: 'KINORAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579498276,
      },
      [BANNERS.ShowUp]: {
        display: 'ssp',
        begun: 579503444,
      },
    },
  },
  mobile: {
    BEGUN_PAD_ID: 507925533,
    banners: {
      [BANNERS.TopBanner]: {
        begun: 508452357,
        p1: 'carht',
      },
      [BANNERS.Rich]: {
        begun: 508483509,
      },
      [BANNERS.Fullscreen]: {
        begun: 508478697,
      },
      [BANNERS.Listing1]: {
        begun: 508464363,
      },
      [BANNERS.Listing2]: {
        begun: 508471605,
      },
      [BANNERS.Listing3]: {
        begun: 508473585,
      },
      [BANNERS.Listing4]: {
        begun: 508473611,
      },
      [BANNERS.Content1]: {
        begun: 508473669,
        p1: 'carhu',
      },
      [BANNERS.Content2]: {
        begun: 508474003,
        p1: 'carhv',
      },
      [BANNERS.Content3]: {
        begun: 508474377,
        p1: 'carhw',
      },
      [BANNERS.Content4]: {
        begun: 508474367,
        p1: 'carhx',
      },
      [BANNERS.Spec]: {
        begun: 508488247,
      },
      [BANNERS.ContentSpec]: {
        begun: 508492879,
      },
      [BANNERS.Inpage]: {
        begun: 508474975,
      },
      [BANNERS.Footer]: {
        begun: 508477649,
        p1: 'carhy',
      },
      [BANNERS.PromoWidget]: {
        data: {
          puid6: 'KINORAMBLERRU_MAIN',
          puid18: 'KINORAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579498278,
      },
    },
  },
};
