export enum CLUSTER_TYPE {
  news = 0,
  photo = 1,
  video = 2,
  article = 3,
  test = 4,
}

export enum CLUSTER_TYPE_ALIAS {
  news = 'News',
  photo = 'Photo',
  video = 'Video',
  article = 'Article',
  test = 'Test',
}

/**
 * Статусы кластера
 */
export enum CLUSTER_STATUS {
  /** Скрыт. 404. Кластер скрыт, так как ссылка на оригинал (источник) */
  toRemove = -1,

  /** Скрыт. 404. Только созданные */
  new = 0,

  /** Активен */
  baked = 1,

  /** Скрыт. Древний статус */
  unknown2 = 2,

  /** Скрыт. Древний статус */
  unknown3 = 3,

  /** Скрыт. Древний статус */
  trash = 4,

  /** Скрыт в админке */
  hidden = 5,

  /** Скрыт. Цензура */
  censored = 6,

  /** Активен */
  uncensored = 7,

  /** Скрыт. Неполнотекстовые кластера. (!) Отказываемся */
  noFulltext = 8,
}

export enum CLUSTER_TOPIC_TAG_ID {
  other = 209,
  ukraine = 191,
  usa = 189,
  newsMoscow = 176,
}

export enum CLUSTER_AUTO_TAG_ID {
  ukraine = 135640,
  usa = 135650,
}

export enum CLUSTER_TAG_ID_TYPE {
  tragedy = 130049,
  death = 150633,
}

/**
 * Алиасы тегов мультикластера для разных вертикалей,
 *  смотрим по алиасам или имени тега, т.к. id на стейджах и проде могут быть разные.
 */
export enum MULTICLUSTER_TAG_ALIASES {
  multiNews = 'multi-news',
  multiWoman = 'multi-woman',
  multiFinance = 'multi-finance',
  multiKino = 'multi-kino',
  multiTravel = 'multi-travel',
  multiWeekend = 'multi-weekend',
  multiDoctor = 'multi-doctor',
  multiSport = 'multi-sport',
  multiAuto = 'multi-auto',
}
