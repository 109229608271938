import { PUIDS } from 'config/common/puids/typings';

import { BANNERS, BannersDataType } from '../typings';

export const finance: BannersDataType = {
  desktop: {
    BEGUN_PAD_ID: 428294616,
    banners: {
      [BANNERS.Topline]: {
        display: 'ssp',
        begun: 542290765,
      },
      [BANNERS.Billboard]: {
        display: 'ssp',
        begun: 429663174,
      },
      [BANNERS.BillboardCommercial1]: {
        display: 'sspScroll',
        begun: 579291216,
      },
      [BANNERS.BillboardCommercial2]: {
        display: 'sspScroll',
        begun: 579291218,
      },
      [BANNERS.BillboardCommercial3]: {
        display: 'sspScroll',
        begun: 579291220,
      },
      [BANNERS.Parallax]: {
        display: 'sspScroll',
        begun: 446876294,
      },
      [BANNERS['240x400']]: {
        display: 'ssp',
        begun: 429344410,
      },
      [BANNERS['240x400_2']]: {
        display: 'sspScroll',
        begun: 429663166,
      },
      [BANNERS.Context]: {
        // native 1
        display: 'ssp',
        data: {
          [PUIDS.puid61]: '162',
        },
        begun: 437940934,
      },
      [BANNERS.Native]: {
        // native 2
        display: 'sspScroll',
        data: {
          [PUIDS.puid61]: '163',
        },
        begun: 437940936,
      },
      [BANNERS.Superfooter]: {
        display: 'sspScroll',
        begun: 429663176,
      },
      [BANNERS['100x70']]: {
        display: 'sspScroll',
        begun: 437940876,
      },
      [BANNERS.Inread]: {
        display: 'ssp',
        begun: 430560764,
      },
      [BANNERS.NativePartner]: {
        // Native3
        display: 'sspScroll',
        begun: 533524341,
        dir1: {
          [PUIDS.puid61]: '833',
        },
      },
      [BANNERS.Ingallery]: {
        display: 'reloadssp',
        begun: 530720663,
      },
      [BANNERS.PromoWidget]: {
        data: {
          puid6: 'FINANCERAMBLERRU_MAIN',
          puid18: 'FINANCERAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579498238,
      },
      [BANNERS.ShowUp]: {
        display: 'ssp',
        begun: 579503436,
      },
    },
  },
  mobile: {
    BEGUN_PAD_ID: 460311650,
    banners: {
      [BANNERS.TopBanner]: {
        begun: 460313229,
        p1: 'bzpkk',
      },
      [BANNERS.Rich]: {
        begun: 460313235,
      },
      [BANNERS.Fullscreen]: {
        begun: 460313233,
      },
      [BANNERS.Listing1]: {
        begun: 460313209,
      },
      [BANNERS.Listing2]: {
        begun: 460313211,
      },
      [BANNERS.Listing3]: {
        begun: 460313213,
      },
      [BANNERS.Listing4]: {
        begun: 460313215,
      },
      [BANNERS.Content1]: {
        begun: 460313217,
        p1: 'bzaei',
      },
      [BANNERS.Content2]: {
        begun: 460313219,
        p1: 'bzaej',
      },
      [BANNERS.Content3]: {
        begun: 460313221,
        p1: 'bzaek',
      },
      [BANNERS.Content4]: {
        begun: 460313223,
        p1: 'bzael',
      },
      [BANNERS.Spec]: {
        begun: 461254106,
      },
      [BANNERS.ContentSpec]: {
        begun: 461254108,
      },
      [BANNERS.Inpage]: {
        begun: 460313231,
      },
      [BANNERS.Footer]: {
        begun: 460313225,
        p1: 'bzaem',
      },
      [BANNERS.PromoWidget]: {
        data: {
          puid6: 'FINANCERAMBLERRU_MAIN',
          puid18: 'FINANCERAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579498240,
      },
    },
  },
};
