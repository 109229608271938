import { PUIDS } from 'config/common/puids/typings';

import { BANNERS, BannersDataType } from '../typings';

export const sport: BannersDataType = {
  desktop: {
    BEGUN_PAD_ID: 448514910,
    banners: {
      [BANNERS.Topline]: {
        display: 'ssp',
        begun: 542288841,
      },
      [BANNERS.Billboard]: {
        display: 'ssp',
        begun: 448516617,
      },
      [BANNERS.BillboardCommercial1]: {
        display: 'sspScroll',
        begun: 579291216,
      },
      [BANNERS.BillboardCommercial2]: {
        display: 'sspScroll',
        begun: 579291218,
      },
      [BANNERS.BillboardCommercial3]: {
        display: 'sspScroll',
        begun: 579291220,
      },
      [BANNERS.Parallax]: {
        display: 'sspScroll',
        begun: 448729150,
      },
      [BANNERS['240x400']]: {
        display: 'ssp',
        begun: 448516611,
      },
      [BANNERS['240x400_2']]: {
        display: 'sspScroll',
        begun: 448516613,
      },
      [BANNERS.Context]: {
        // native 1
        display: 'ssp',
        data: {
          [PUIDS.puid61]: '171',
        },
        begun: 448516625,
      },
      [BANNERS.Native]: {
        // native 2
        display: 'sspScroll',
        data: {
          [PUIDS.puid61]: '172',
        },
        begun: 448516627,
      },
      [BANNERS.Superfooter]: {
        display: 'sspScroll',
        begun: 448516609,
      },
      [BANNERS['100x70']]: {
        display: 'sspScroll',
        begun: 448516619,
      },
      [BANNERS.Inread]: {
        display: 'ssp',
        begun: 448516623,
      },
      [BANNERS.NativePartner]: {
        // Native3
        display: 'sspScroll',
        begun: 473298116,
        dir1: {
          [PUIDS.puid61]: '842',
        },
      },
      [BANNERS.Ingallery]: {
        display: 'reloadssp',
        begun: 530717921,
      },
      [BANNERS.Fonbet]: {
        display: 'ssp',
        begun: 522973415,
      },
      [BANNERS.PromoWidget]: {
        data: {
          puid6: 'SPORTRAMBLERRU_MAIN',
          puid18: 'SPORTRAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579498284,
      },
      [BANNERS.ShowUp]: {
        display: 'ssp',
        begun: 579503442,
      },
    },
  },
  mobile: {
    BEGUN_PAD_ID: 460428472,
    banners: {
      [BANNERS.TopBanner]: {
        begun: 460428556,
        p1: 'bzmes',
      },
      [BANNERS.Rich]: {
        begun: 460428716,
      },
      [BANNERS.Fullscreen]: {
        begun: 460428654,
      },
      [BANNERS.Listing1]: {
        begun: 460428676,
      },
      [BANNERS.Listing2]: {
        begun: 460428690,
      },
      [BANNERS.Listing3]: {
        begun: 460428692,
      },
      [BANNERS.Listing4]: {
        begun: 460428694,
      },
      [BANNERS.Content1]: {
        begun: 460428608,
        p1: 'bymhi',
      },
      [BANNERS.Content2]: {
        begun: 460428614,
        p1: 'bymhj',
      },
      [BANNERS.Content3]: {
        begun: 460428618,
        p1: 'bymhl',
      },
      [BANNERS.Content4]: {
        begun: 460428626,
        p1: 'bymhm',
      },
      [BANNERS.Spec]: {
        begun: 460428700,
      },
      [BANNERS.ContentSpec]: {
        begun: 460428636,
      },
      [BANNERS.Inpage]: {
        begun: 460428602,
      },
      [BANNERS.Footer]: {
        begun: 460428638,
        p1: 'bymhn',
      },
      [BANNERS.Fonbet]: {
        begun: 522979309,
      },
      [BANNERS.PromoWidget]: {
        data: {
          puid6: 'SPORTRAMBLERRU_MAIN',
          puid18: 'SPORTRAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579498286,
      },
    },
  },
};
