import { Middleware, Reducer, configureStore } from '@reduxjs/toolkit';

import { config } from 'config';

import createLogger from './logger';

/**
 * Конфигурация redux-store проекта
 * @param reducer - редьюсер
 * @param preloadedState - начальный стейт
 * @param preloadedState.runtime - общая информация для приложения
 */
export const getStore = (
  reducer: Reducer,
  preloadedState: { runtime: Runtime },
): AppStore => {
  const middleware: Middleware[] = [];

  if (__DEV__ && config.REDUX_LOGGER) {
    middleware.push(createLogger() as Middleware);
  }

  const store = configureStore({
    reducer,
    preloadedState,
    devTools: __DEV__,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }).concat(middleware),
  });

  if (__DEV__ && module.hot) {
    module.hot.accept('../desktop/redux/reducers');
    module.hot.accept('../longread/redux/reducers');
    module.hot.accept('../mobile/redux/reducers');
  }

  return store;
};
