import { PROFILES } from './typings';

export const COMMON_REDIS_RETRY_INTERVAL_IN_MS = 5000;

export const STAGE_PROFILES: string[] = [
  PROFILES.stageTest,
  PROFILES.stage1Test,
  PROFILES.stage2Test,
  PROFILES.stage3Test,
  PROFILES.stage4Test,
  PROFILES.stage5Test,
  PROFILES.stage6Test,
  PROFILES.extestmTest,
];
export const PROD_PROFILES: string[] = [
  PROFILES.stageProd,
  PROFILES.stage1Prod,
  PROFILES.stage2Prod,
  PROFILES.stage3Prod,
  PROFILES.stage4Prod,
  PROFILES.stage5Prod,
  PROFILES.stage6Prod,
  PROFILES.extestmProd,
  PROFILES.release,
];
