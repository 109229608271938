import { auto } from './data/auto-data';
import { common } from './data/common-data';
import { doctor } from './data/doctor-data';
import { finance } from './data/finance-data';
import { kino } from './data/kino-data';
import { news } from './data/news-data';
import { sport } from './data/sport-data';
import { travel } from './data/travel-data';
import { weekend } from './data/weekend-data';
import { woman } from './data/woman-data';
import { BannersMapType } from './typings';

export const BANNERS: BannersMapType = {
  ai: common,
  auto,
  doctor,
  dom: common,
  family: common,
  finance,
  horoscopes: news,
  kino,
  life: common,
  news,
  pro: common,
  sci: common,
  sport,
  travel,
  unknown: news,
  weekend,
  woman,
};
