export enum TOPIC_STATUS {
  /** Отключенная рубрика */
  inactive = 0,

  /** Активная рубрика */
  active = 1,

  /** Скрытая рубрика. Попасть можно по прямому урлу */
  hidden = 2,
}
