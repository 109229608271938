import { PUIDS } from 'config/common/puids/typings';

import { BANNERS, BannersDataType } from '../typings';

export const news: BannersDataType = {
  desktop: {
    BEGUN_PAD_ID: 428678158,
    banners: {
      [BANNERS.Topline]: {
        display: 'ssp',
        begun: 542286633,
      },
      [BANNERS.Billboard]: {
        display: 'ssp',
        begun: 429663120,
      },
      [BANNERS.BillboardCommercial1]: {
        display: 'sspScroll',
        begun: 579291216,
      },
      [BANNERS.BillboardCommercial2]: {
        display: 'sspScroll',
        begun: 579291218,
      },
      [BANNERS.BillboardCommercial3]: {
        display: 'sspScroll',
        begun: 579291220,
      },
      [BANNERS.Parallax]: {
        display: 'sspScroll',
        begun: 437121492,
      },
      [BANNERS['240x400']]: {
        display: 'reloadssp',
        begun: 429663110,
      },
      [BANNERS['240x400_2']]: {
        display: 'sspScroll',
        begun: 429663112,
      },
      [BANNERS.Context]: {
        // native 1
        display: 'ssp',
        data: {
          [PUIDS.puid61]: '193',
        },
        begun: 432396582,
      },
      [BANNERS.Native]: {
        // native 2
        display: 'sspScroll',
        data: {
          [PUIDS.puid61]: '194',
        },
        begun: 432396588,
      },
      [BANNERS.Superfooter]: {
        display: 'sspScroll',
        begun: 429663124,
      },
      [BANNERS['100x70']]: {
        display: 'sspScroll',
        begun: 432396590,
      },
      [BANNERS.Inread]: {
        display: 'ssp',
        begun: 432396574,
      },
      [BANNERS.NativePartner]: {
        // Native3
        display: 'sspScroll',
        begun: 472983170,
        dir1: {
          [PUIDS.puid61]: '830',
        },
      },
      [BANNERS.Ingallery]: {
        display: 'reloadssp',
        begun: 530717425,
      },
      [BANNERS.PromoWidget]: {
        data: {
          puid6: 'NEWSRAMBLERRU_MAIN',
          puid18: 'NEWSRAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579498254,
      },
      [BANNERS.ShowUp]: {
        display: 'ssp',
        begun: 579503434,
      },
    },
  },
  mobile: {
    BEGUN_PAD_ID: 437707674,
    banners: {
      [BANNERS.TopBanner]: {
        begun: 437708104,
        p1: 'bzmeq',
      },
      [BANNERS.Rich]: {
        begun: 437708080,
      },
      [BANNERS.Fullscreen]: {
        begun: 437708024,
      },
      [BANNERS.Listing1]: {
        begun: 437707752,
      },
      [BANNERS.Listing2]: {
        begun: 437707762,
      },
      [BANNERS.Listing3]: {
        begun: 437707772,
      },
      [BANNERS.Listing4]: {
        begun: 437707774,
      },
      [BANNERS.Content1]: {
        begun: 437707778,
        p1: 'bwxfh',
      },
      [BANNERS.Content2]: {
        begun: 437707794,
        p1: 'bymxx',
      },
      [BANNERS.Content3]: {
        begun: 437707802,
        p1: 'bymxy',
      },
      [BANNERS.Content4]: {
        begun: 437707804,
        p1: 'bymxz',
      },
      [BANNERS.Spec]: {
        begun: 457396186,
      },
      [BANNERS.ContentSpec]: {
        begun: 457396208,
      },
      [BANNERS.Inpage]: {
        begun: 437708070,
      },
      [BANNERS.Footer]: {
        begun: 437707812,
        p1: 'bymya',
      },
      [BANNERS.PromoWidget]: {
        data: {
          puid6: 'NEWSRAMBLERRU_MAIN',
          puid18: 'NEWSRAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579498256,
      },
    },
  },
};
