import { PROJECT_ALIASES } from '../project/typings';

const GA_PORTAL_CODE = 'UA-106831090-1';
const GA_NEWS_CODE = 'UA-7679851-1';

export const COUNTERS = {
  resources: {
    counters: {
      top100: 'https://st.top100.ru/top100/top100.js',
      tns: 'https://www.tns-counter.ru/V13a****rambler_ru/ru/UTF-8/tmsec=rambler_',
      liveinternet: 'https://counter.yadro.ru/hit',
      mediascope: [
        {
          account: 'rambler_ad',
          tmsec: 'rambler_cid1100104-posid2155079',
        },
        {
          account: 'rambler_ad',
          tmsec: 'rambler_cid1100108-posid2155111',
        },
      ],
    },
  },
  okko: {
    top100: '7118344',
  },
  sberPortal: {
    top100: '7726560',
  },
  sberStats: {
    top100: '7728281',
  },
  [PROJECT_ALIASES.Ai]: {
    ya_metrika_portal: '26649402',
    ya_metrika_common: '85474414',
    ya_metrika_web_mob: '98063088',
    top100: '7730711',
    ga: GA_NEWS_CODE,
    ga_portal: GA_PORTAL_CODE,
    tns: 'ai',
  },
  [PROJECT_ALIASES.Auto]: {
    ya_metrika_portal: '26649402',
    ya_metrika_common: '85474414',
    ya_metrika_web_mob: '44184634',
    top100: '1349493',
    ga: 'UA-97378291-1',
    ga_portal: GA_PORTAL_CODE,
    tns: 'auto',
  },
  [PROJECT_ALIASES.Doctor]: {
    ya_metrika_portal: '26649402',
    ya_metrika_common: '85474414',
    ya_metrika_web_mob: '44184904',
    top100: '4476332',
    ga: 'UA-97390855-1',
    ga_portal: GA_PORTAL_CODE,
    tns: 'doctor',
  },
  [PROJECT_ALIASES.Dom]: {
    ya_metrika_portal: '26649402',
    ya_metrika_common: '85474414',
    ya_metrika_web_mob: '98063069',
    top100: '7730710',
    ga: GA_NEWS_CODE,
    ga_portal: GA_PORTAL_CODE,
    tns: 'dom',
  },
  [PROJECT_ALIASES.Family]: {
    ya_metrika_portal: '26649402',
    ya_metrika_common: '85474414',
    ya_metrika_web_mob: '98063114',
    top100: '7730713',
    ga: GA_NEWS_CODE,
    ga_portal: GA_PORTAL_CODE,
    tns: 'family',
  },
  [PROJECT_ALIASES.Finance]: {
    ya_metrika_portal: '26649402',
    ya_metrika_common: '85474414',
    ya_metrika_web_mob: '26557647',
    top100: '187815',
    ga: 'UA-11914141-3',
    ga_portal: GA_PORTAL_CODE,
    tns: 'finance',
  },
  [PROJECT_ALIASES.Horoscopes]: {
    ya_metrika_portal: '26649402',
    ya_metrika_common: '85474414',
    ya_metrika_web_mob: '25662845',
    top100: '127846',
    ga: GA_NEWS_CODE,
    ga_portal: GA_PORTAL_CODE,
    tns: 'news',
  },
  [PROJECT_ALIASES.Kino]: {
    ya_metrika_portal: '26649402',
    ya_metrika_common: '85474414',
    ya_metrika_web_mob: '44184864',
    top100: '4476331',
    ga: 'UA-97393758-1',
    ga_portal: GA_PORTAL_CODE,
    tns: 'kino',
  },
  [PROJECT_ALIASES.Life]: {
    ya_metrika_portal: '26649402',
    ya_metrika_common: '85474414',
    ya_metrika_web_mob: '98063032',
    top100: '7730687',
    ga: GA_NEWS_CODE,
    ga_portal: GA_PORTAL_CODE,
    tns: 'life',
  },
  [PROJECT_ALIASES.News]: {
    ya_metrika_portal: '26649402',
    ya_metrika_common: '85474414',
    ya_metrika_web_mob: '25662845',
    top100: '127846',
    ga: GA_NEWS_CODE,
    ga_portal: GA_PORTAL_CODE,
    tns: 'news',
  },
  [PROJECT_ALIASES.Pro]: {
    ya_metrika_portal: '26649402',
    ya_metrika_common: '85474414',
    ya_metrika_web_mob: '98063051',
    top100: '7730709',
    ga: GA_NEWS_CODE,
    ga_portal: GA_PORTAL_CODE,
    tns: 'pro',
  },
  [PROJECT_ALIASES.Sci]: {
    ya_metrika_portal: '26649402',
    ya_metrika_common: '85474414',
    ya_metrika_web_mob: '98063106',
    top100: '7730712',
    ga: GA_NEWS_CODE,
    ga_portal: GA_PORTAL_CODE,
    tns: 'sci',
  },
  [PROJECT_ALIASES.Sport]: {
    ya_metrika_portal: '26649402',
    ya_metrika_common: '85474414',
    ya_metrika_web_mob: '36855860',
    top100: '164843',
    ga: 'UA-12697350-1',
    ga_portal: 'UA-106831090-1',
    tns: 'sport',
  },
  [PROJECT_ALIASES.Travel]: {
    ya_metrika_portal: '26649402',
    ya_metrika_common: '85474414',
    ya_metrika_web_mob: '29598565',
    top100: '357490',
    ga: 'UA-8038853-23',
    ga_portal: GA_PORTAL_CODE,
    tns: 'travel',
  },
  [PROJECT_ALIASES.Unknown]: {
    ya_metrika_portal: '26649402',
    ya_metrika_common: '85474414',
    ya_metrika_web_mob: '25662845',
    top100: '127846',
    ga: GA_NEWS_CODE,
    ga_portal: GA_PORTAL_CODE,
    tns: 'news',
  },
  [PROJECT_ALIASES.Weekend]: {
    ya_metrika_portal: '26649402',
    ya_metrika_common: '85474414',
    ya_metrika_web_mob: '26969997',
    top100: '3104202',
    ga: 'UA-18802658-2',
    ga_portal: GA_PORTAL_CODE,
    tns: 'saturday',
  },
  [PROJECT_ALIASES.Woman]: {
    ya_metrika_portal: '26649402',
    ya_metrika_web_mob: '44184774',
    ya_metrika_common: '85474414',
    top100: '4476330',
    ga: 'UA-97403060-1',
    ga_portal: GA_PORTAL_CODE,
    tns: 'woman',
  },
};
