export enum PROJECT_IDS {
  Unknown = -1,
  News = 1,
  Auto = 2,
  Woman = 4,
  Sport = 5,
  Weekend = 6,
  Finance = 7,
  Doctor = 8,
  Kino = 10,
  Travel = 11,
  Horoscopes = 12,
  Family = 15,
  Life = 16,
  Pro = 17,
  Dom = 18,
  Ai = 19,
  Sci = 20,
}

export enum PROJECT_ALIASES {
  Ai = 'ai',
  Auto = 'auto',
  Doctor = 'doctor',
  Dom = 'dom',
  Family = 'family',
  Finance = 'finance',
  Horoscopes = 'horoscopes',
  Kino = 'kino',
  Life = 'life',
  News = 'news',
  Pro = 'pro',
  Sci = 'sci',
  Sport = 'sport',
  Travel = 'travel',
  Unknown = 'unknown',
  Weekend = 'weekend',
  Woman = 'woman',
}
