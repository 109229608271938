export enum PROFILES {
  stageTest = 'stage:test',
  stageProd = 'stage:prod',
  stage1Test = 'stage1:test',
  stage1Prod = 'stage1:prod',
  stage2Test = 'stage2:test',
  stage2Prod = 'stage2:prod',
  stage3Test = 'stage3:test',
  stage3Prod = 'stage3:prod',
  stage4Test = 'stage4:test',
  stage4Prod = 'stage4:prod',
  stage5Test = 'stage5:test',
  stage5Prod = 'stage5:prod',
  stage6Test = 'stage6:test',
  stage6Prod = 'stage6:prod',
  extestmTest = 'extestm:test',
  extestmProd = 'extestm:prod',
  release = 'release',
  local = 'local',
}
