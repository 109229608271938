import { PUIDS } from 'config/common/puids/typings';

import { BANNERS, BannersDataType } from '../typings';

export const woman: BannersDataType = {
  desktop: {
    BEGUN_PAD_ID: 455518210,
    banners: {
      [BANNERS.Topline]: {
        display: 'ssp',
        begun: 542287483,
      },
      [BANNERS.Billboard]: {
        display: 'ssp',
        begun: 455518238,
      },
      [BANNERS.BillboardCommercial1]: {
        display: 'sspScroll',
        begun: 579291216,
      },
      [BANNERS.BillboardCommercial2]: {
        display: 'sspScroll',
        begun: 579291218,
      },
      [BANNERS.BillboardCommercial3]: {
        display: 'sspScroll',
        begun: 579291220,
      },
      [BANNERS.Parallax]: {
        display: 'sspScroll',
        begun: 455518370,
      },
      [BANNERS['240x400']]: {
        display: 'ssp',
        begun: 455518262,
      },
      [BANNERS['240x400_2']]: {
        display: 'sspScroll',
        begun: 455518264,
      },
      [BANNERS.Context]: {
        // native 1
        display: 'ssp',
        data: {
          [PUIDS.puid61]: '218',
        },
        begun: 455518342,
      },
      [BANNERS.ContextRight]: {
        display: 'sspScroll',
        begun: 560473833,
      },
      [BANNERS.ContextRight2]: {
        display: 'sspScroll',
        begun: 560473835,
      },
      [BANNERS.Native]: {
        // native 2
        display: 'sspScroll',
        data: {
          [PUIDS.puid61]: '219',
        },
        begun: 455518344,
      },
      [BANNERS.Superfooter]: {
        display: 'sspScroll',
        begun: 455518882,
      },
      [BANNERS['100x70']]: {
        display: 'sspScroll',
        begun: 455518892,
      },
      [BANNERS.Inread]: {
        display: 'ssp',
        begun: 455518276,
      },
      [BANNERS.NativePartner]: {
        // Native3
        display: 'sspScroll',
        begun: 473294432,
        dir1: {
          [PUIDS.puid61]: '836',
        },
      },
      [BANNERS.Ingallery]: {
        display: 'reloadssp',
        begun: 530719467,
      },
      [BANNERS.PromoWidget]: {
        data: {
          puid6: 'WOMANRAMBLERRU_MAIN',
          puid18: 'WOMANRAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579498280,
      },
      [BANNERS.ShowUp]: {
        display: 'ssp',
        begun: 579503440,
      },
    },
  },
  mobile: {
    BEGUN_PAD_ID: 460429584,
    banners: {
      [BANNERS.TopBanner]: {
        begun: 460429640,
        p1: 'bzmeu',
      },
      [BANNERS.Rich]: {
        begun: 460429730,
      },
      [BANNERS.Fullscreen]: {
        begun: 460429718,
      },
      [BANNERS.Listing1]: {
        begun: 460429720,
      },
      [BANNERS.Listing2]: {
        begun: 460429722,
      },
      [BANNERS.Listing3]: {
        begun: 460429724,
      },
      [BANNERS.Listing4]: {
        begun: 460429726,
      },
      [BANNERS.Content1]: {
        begun: 460429702,
        p1: 'bymfg',
      },
      [BANNERS.Content2]: {
        begun: 460429706,
        p1: 'bymfi',
      },
      [BANNERS.Content3]: {
        begun: 460429708,
        p1: 'bymfk',
      },
      [BANNERS.Content4]: {
        begun: 460429710,
        p1: 'bymfm',
      },
      [BANNERS.Spec]: {
        begun: 460429728,
      },
      [BANNERS.ContentSpec]: {
        begun: 460429712,
      },
      [BANNERS.Inpage]: {
        begun: 460429642,
      },
      [BANNERS.Footer]: {
        begun: 460429714,
        p1: 'bymfn',
      },
      [BANNERS.PromoWidget]: {
        data: {
          puid6: 'WOMANRAMBLERRU_MAIN',
          puid18: 'WOMANRAMBLERRU_MAIN_MAIN',
        },
        display: 'ssp',
        begun: 579498282,
      },
    },
  },
};
